@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~react-image-gallery/styles/css/image-gallery.css";

.gallery {
    width: 90vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-auto-rows: minmax(12vh, 32vh);
    grid-auto-flow: dense;
    grid-gap: 24px;
}
.grid-item {
    /* border: 2px solid #000; */
    overflow: hidden;
}

.grid-item > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
